<template>
  <div class="login-wrap" >
    <div class="content">
      <div v-if="!showApply" class="login-box">
        <div v-if="webName === '聪听数智舆情平台'">
          <i v-show="false" v-if="showForm" class="iconfont icon-unie" @click="showForm = false"></i>
          <i v-else class="iconfont icon-uniE" @click="showForm = true"></i>
        </div>
        <div  v-if="showForm" class="login-form">
          <h2 class="title">{{ webName }}</h2>
          <el-input v-model="name" placeholder="用户名"><i slot="prefix" style="margin-top: -4px" class="el-input__icon el-icon-user"></i></el-input>
          <el-input v-model="password" placeholder="密码" show-password><i slot="prefix" style="margin-top: -4px" class="el-input__icon el-icon-lock"></i></el-input>
          <captcha-item :name="name" :password="password" v-if="captchaShow" @close="captchaShow = false"></captcha-item>
<!--          <el-input v-model="captcha" placeholder="验证码" style="width: 200px"/>-->
<!--          <img :src="captchasrc" style="width: 150px;height: 40px;margin-bottom: -15px;margin-left: 10px;border-radius: 4px" @click="refrshCaptchasrc">-->
          <el-button type="primary" @click="login">登&nbsp;&nbsp;录</el-button>
        </div>
        <div v-else class="login-code">
          <div class="title">扫码登录</div>
          <div class="code-imgWrapper">
            <img src="../../assets/images/qrcode.png" >
          </div>
        </div>
        <div v-if="webName === '聪听数智舆情平台'" class="tips">还没有帐号？
          <el-link :underline="false" type="primary" @click="showApply = true">申请试用</el-link>
        </div>
      </div>
      <div v-else class="apply-box">
        <h2 class="title">申请试用</h2>
        <el-input v-model="form.call" placeholder="联系人"/>
        <el-input v-model="form.linkTelephone" placeholder="联系电话" />
        <!-- <el-input v-model="form.email" placeholder="邮箱"/> -->
        <el-input v-model="form.company" placeholder="公司名称"/>
        <el-button type="primary" @click="submitApply">提&nbsp;&nbsp;交</el-button>
        <div class="tips">已有帐号？
          <el-link :underline="false" type="primary" @click="showApply = false">立即登录</el-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {captcha} from "@/api/user";
import {apiBaseURL} from "@/utils/request";
import { submitApply } from '@/api/settings/apply'
import captchaItem from '@/components/captcha'
export default {
  components:{
    captchaItem
  },
  data () {
    return ({
      name: '',
      password: '',
      uuid: '',
      captcha: '',
      captchasrc: '',
      showForm: true,
      showApply:false,
      captchaShow:false,
      form:{
        call:"",
        linkTelephone:"",
        company:""
      },
      webName: '聪听数智舆情平台'
    })
  },
  mounted() {
    // this.uuid = this.generateUUID()
    window.addEventListener('keydown',this.keyDown)
    // this.captchasrc = apiBaseURL + '/captcha?uuid=' + this.uuid
    var domain = document.domain
    this.webName = '聪听数智舆情平台'
    document.title = this.webName + ' - 欢迎登录'
  },
  destroyed(){
    window.removeEventListener('keydown',this.keyDown,false)
  },
  methods: {
    login () {
      if(this.name == ""){
        this.$message.error('用户名不得为空');
        return;
      }
      if(this.password == ""){
        this.$message.error('密码不得为空');
        return;
      }
      if (this.webName === '聪听数智舆情平台') {
        this.captchaShow = !this.captchaShow
      } else {
        const loading = this.$loading({
          lock: true,
          text: '登录中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        try {
          this.$store.dispatch('user/login', {
            userInfo: {username: this.name, password: this.password, uuid: this.uuid, captcha: this.captcha},
            error: (res) => {
              if (res.code === 5001) {
                this.$message.error('试用项目已到期！')
              } else {
                this.$message.error(res.msg)
              }
              this.refrshCaptchasrc()
              loading.close()
            },
            callback: () => {
              loading.close()
              if (window.localStorage.getItem("logout")) {
                window.localStorage.setItem("logout", '')
              }
              this.$router.push('/')
            }
          })
        } catch (err) {
          console.log('123')
          console.log(err)
        }
      }
    },
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
      });
      return uuid;
    },
     keyDown(e){
      //如果是回车则执行登录方法
      if(e.keyCode == 13){
        this.login();
      }
    },
    async submitApply () {
      const res = await submitApply(this.form)
      if(res.code == 0){
        this.$message.success(res.msg)
      }
    },
    refrshCaptchasrc() {
      this.uuid = this.generateUUID()
      this.captchasrc = apiBaseURL + '/captcha?uuid=' + this.uuid
      this.captcha = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100vw;
  height: 100vh;
  background: url("/login_bg.png") no-repeat;
  background-size: cover;
  .iconfont{
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 50px;
    line-height: 50px;
    cursor: pointer;
    color: #55aaff;
  }
  .content {
    width: 485px;
    // height: 400px;
    padding-bottom: 60px;
    background: rgba(243, 247, 253, 0.2);
    border-radius: 10px;
    padding-top: 29px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      text-align: center;
      font-weight: bolder;
      margin: 0;
      font-size: 27px;
      color: #fffbff;
      margin-bottom: 40px;
    }
    .login-box,.apply-box{
      width: 100%;
      margin: 0 auto;
      // text-align: center;
      .tips{
        text-align: center;
        font-size: 14px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        .el-link.el-link--primary{
          vertical-align: baseline;
        }
      }
      .code-imgWrapper{
        background-color: #fff;
        width: 200px;
        margin: 0 auto;
        padding: 10px;
        img{
          width: 180px;
          height: 180px;
        }
      }
      .el-input {
        // margin: 0 auto;
        width: 360px;
        height: 50px;
        margin-bottom: 10px;
        margin-left: 13%;
        .el-input__inner {
          height: 50px;
          line-height: 50px;
        }
      }
      .el-button {
        width: 360px;
        height: 50px;
        margin-top: 10px;
        margin-left: 13%;
      }
    }
  }
}
</style>
